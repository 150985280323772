<template lang="pug">
email-edit(:emailId="emailId")
</template>

<script>
import EmailEdit from "@/components/email/Edit.vue";
import { validatePermission } from "@/Ability";

const COMPONENT_NAME = "ViewEditEmail";

export default {
  name: COMPONENT_NAME,
  components: {
    "email-edit": EmailEdit,
  },
  methods: {},
  data() {
    return {
      emailId: "",
    };
  },
  created() {
    this.emailId = String(this.$route.params.id);
    validatePermission(this, "update", "email", "Edit");
  },
};
</script>