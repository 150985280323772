<template>
  <div class="container container-editor-email">
    <div class="container-bar-editor-email my-2 rounded">
      <h1>{{ this.$t("custom_email.title_view_edit") + " " }}{{ name }}</h1>
      <button
        class="bg-yellow-500 hover:bg-yellow-100 text-gray-800 font-semibold py-2 px-4 border border-yellow-400 rounded shadow"
        @click="updateDesign"
        :disabled="isLoading"
      >
        {{ this.$t("custom_email.btn_update") }}
      </button>
      <button
        class="bg-red-500 hover:bg-white text-gray-800 hover:text-red-900 font-semibold py-2 px-4 border border-red-800 rounded shadow"
        @click="deleteDesign"
        :disabled="isLoading"
      >
        {{ this.$t("custom_email.btn_delete") }}
      </button>
    </div>
    <EmailEditor ref="emailEditor" @load="editorLoaded" />
    <vue-form
      v-if="!isLoading"
      ref="EmailForm"
      :popupActive="popupActive"
      :original_name="name"
      :original_status_id="status_id"
      :selectedNewsPrev="newsSelected"
      :original_company_id="company_id"
      @submitForm="getDataForm"
      @closePopup="closeModal"
    ></vue-form>
  </div>
</template>

<script>
import { EmailEditor } from "vue-email-editor";
import HTTP from "@/http-common";
import FieldsCustomEmail from "@/fields/email/fieldsEmails";
import EmailForm from "./Form";

const NAME = "EmailEdit";

export default {
  name: NAME,
  components: {
    EmailEditor,
    "vue-form": EmailForm,
  },
  data() {
    return {
      template: {},
      id: "",
      name: "",
      company_id: null,
      status_id: null,
      isLoadingC: true,
      isLoadingR: true,
      popupActive: false,
      newsSelected:""
    };
  },
  props: {
    emailId: {
      type: String,
      default: () => "",
    },
  },
  computed: {
    isLoading() {
      return this.isLoadingC || this.isLoadingR;
    },
  },
  watch: {
    isLoading(newValue) {
      if (!newValue) {
        this.$refs.emailEditor.editor.loadDesign(this.template);
      }
    },
  },
  methods: {
    editorLoaded() {
      this.isLoadingC = false;
      // this.$refs.emailEditor.editor.setMergeTagsConfig(
      //   FieldsCustomEmail.mergeTagsConfig
      // );
      this.$refs.emailEditor.editor.setMergeTags(FieldsCustomEmail.mergeTags);
      this.$refs.emailEditor.editor.registerCallback(
        "image",
        this.callbackImage
      );
    },
    async deleteDesign() {
      const response = await this.$swal({
        position: "center",
        type: "error",
        title: this.$t("danger"),
        text: this.$t("custom_email.message_delete_template"),
        confirmButtonColor: "#ea5455",
        cancelButtonColor: "#24292e",
        showLoaderOnConfirm: true,
        showConfirmButton: true,
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
      });
      if (response.value) {
        try {
          await HTTP.delete(`v1/custom-emails/${this.emailId}`);
          return this.$router.push({ name: "index-email" });
        } catch (error) {
          return this.$swal({
            position: "center",
            type: "warning",
            confirmButtonColor: "#FDD402",
            title: "Oops...",
            text: error.response.data.error.message,
          });
        }
      }
    },
    async getDataForm(formData) {
      this.closeModal();
      try {
        await this.sendDesign(
          formData.name,
          formData.company_id,
          formData.status_id,
          formData.newsSelected
        );
        await this.$swal({
          position: "center",
          type: "success",
          title: this.$t("custom_email.title_success_template"),
          confirmButtonColor: "#FDD402",
          allowOutsideClick: false,
          timer: 3000,
        });
        this.$router.push({ name: "index-email" });
      } catch (error) {
        return this.$swal({
          position: "center",
          type: "warning",
          confirmButtonColor: "#FDD402",
          title: "Oops...",
          text: error.response.data.error.message,
        });
      }
    },
    async updateDesign() {
      this.popupActive = true;
    },
    sendDesign(name, company_id, status_id,newsSelected) {
      return new Promise((resolve, reject) => {
        this.$refs.emailEditor.editor.exportHtml(async (design) => {
          try {
            await HTTP.put("v1/custom-emails", {
              id: this.emailId,
              name,
              company_id,
              status_id,
              jsonModel: { body: design.design.body },
              htmlModel: design.html,
              newsSelected
            });
            resolve();
          } catch (error) {
            reject(error);
          }
        });
      });
    },
    async callbackImage(file, done) {
      let data = new FormData();
      let url;
      data.append("data", file.attachments[0]);
      try {
        let response = await HTTP.post("v1/custom-emails/image", data);
        url = response.data.url;
        done({ progress: 100, url });
      } catch (error) {
        done({ progress: 100, url: "ERROR SERVER IN URL OF THE FILE" });
      }
    },
    async getTemplate() {
      try {
        let templateGet = await HTTP.query(`v1/custom-emails/${this.emailId}`);
        if (templateGet.data.status === "success") {
          this.isLoadingR = false;
          this.template = templateGet.data.json_model;
          this.name = templateGet.data.name;
          this.company_id = templateGet.data.company_id;
          this.status_id = templateGet.data.status_id;
          this.newsSelected= templateGet.data.newness_id
        } else {
          throw "error";
        }
      } catch (error) {
        throw error;
      }
    },
    closeModal() {
      this.popupActive = false;
    },
  },
  async mounted() {
    try {
      await this.getTemplate();
    } catch (error) {
      await this.$swal({
        position: "center",
        type: "warning",
        confirmButtonColor: "#FDD402",
        title: "Oops...",
        text: this.$t("custom_email.title_error_load"),
        timer: 3000,
      });
      return this.$router.push({ name: "index-email" });
    }
  },
};
</script>

<style scoped>
.container-editor-email {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 550px;
}

.container-bar-editor-email {
  background-color: #333;
  flex: 1;
  padding: 10px;
  display: flex;
  max-height: 60px;
}

.container-bar-editor-email h1 {
  flex: 1;
  text-align: left;
  color: #ffffff;
}

.container-bar-editor-email button {
  flex: 1;
  margin-left: 10px;
  max-width: 150px;
  cursor: pointer;
}
</style>
